import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useReadLocalStorage } from 'usehooks-ts';

import LoginFinish from './Finish';
import useLoginHook from '../hooks/useLoginHook';

const AccessScreen = dynamic(() => import('./AccessScreen'));
const EmailScreen = dynamic(() => import('./EmailScreen'));
const NewPasswordScreen = dynamic(() => import('./NewPasswordScreen'));
// const NewUserScreen = dynamic(() => import('./NewUserScreen'));
const PasswordScreen = dynamic(() => import('./PasswordScreen'));
const SMSScreen = dynamic(() => import('./SMSScreen'));

type Screens = {
  [key: string]: JSX.Element;
};

const LoginFlow = () => {
  const {
    identifiant,
    setIdentifiant,
    password,
    setPassword,
    loginKeycloak,
    verifyLastUserConnexion,
    loggedUserData,
    sendOtpSms,
    verificationCode,
    setVerificationCode,
    VerifyOtpSms,
    newPassword,
    newRePassword,
    setnewPassword,
    setnewRePassword,
    updatePassword,
    error,
    clickForgetPassword,
  } = useLoginHook();

  const expiresIn = useReadLocalStorage<number>('expires_in');

  const isAuthenticated = useMemo(
    () => Date.now() / 1000 < +(expiresIn || 0),
    [expiresIn]
  );

  const router = useRouter();

  const SCREENS: Screens = {
    email: (
      <EmailScreen
        identifiant={identifiant}
        setIdentifiant={setIdentifiant}
        handleNextClick={verifyLastUserConnexion}
        error={error.email}
      />
    ),
    password: (
      <PasswordScreen
        setPassword={setPassword}
        password={password}
        onClickConnexion={loginKeycloak}
        clickForgetPassword={clickForgetPassword}
        error={error.password}
      />
    ),
    'new-password': (
      <NewPasswordScreen
        password={newPassword}
        setPassword={setnewPassword}
        confirmPassword={newRePassword}
        setConfirmPassword={setnewRePassword}
        clickConnexion={updatePassword}
        error={error['new-password']}
      />
    ),
    access: (
      <AccessScreen
        phoneNumber={loggedUserData?.phonenumber}
        onClickPasswordMethod={() =>
          router.push({
            query: {
              ...router.query,
              screen: 'password',
            },
          })
        }
        onClickSmsMethod={sendOtpSms}
        error={error.access}
      />
    ),
    sms: (
      <SMSScreen
        verifyCode={verificationCode}
        setVerifyCode={setVerificationCode}
        reSendVerificationCode={sendOtpSms}
        handleNextClick={VerifyOtpSms}
        error={error.sms}
      />
    ),
    // 'new-user': <NewUserScreen error={error['new-user']} />,
    finish: <LoginFinish />,
  };
  const { screen, redirect } = router.query;

  const screenName =
    isAuthenticated && redirect === undefined
      ? 'finish'
      : screen?.toString() || 'email';
  return <>{SCREENS[screenName]}</>;
};

export default LoginFlow;
