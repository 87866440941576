import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { SSRConfig } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { cleanObject } from '@/lib/utils';
import LoginFlow from '@/modules/auth/components/LoginFlow';
import Layout from '@/modules/core/components/Layout';

const LoginPage = () => (
  <div className="flex -ml-2 md:ml-0 max-w-screen">
    <span className="m-0 mx-auto md:m-auto">
      <LoginFlow />
    </span>
  </div>
);

export default LoginPage;

// Dynamic layout configuration
LoginPage.getLayout = function getLayout(page: JSX.Element) {
  return (
    <Layout>
      <Layout.Wide>{page}</Layout.Wide>
    </Layout>
  );
};

// Server side code and i18n
export const getServerSideProps: GetServerSideProps = async ({
  locale,
}): Promise<GetServerSidePropsResult<SSRConfig>> => ({
  props: cleanObject({
    ...(await serverSideTranslations(locale || 'en')),
  }),
});
